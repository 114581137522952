<template>
<div
  :class="column.props.widths[0] === '' ? 'uk-width-expand' : 'uk-width-' + column.props.widths[0]"
  class="uk-flex uk-flex-column"
  @click="initEmptyElementClick($event)">
  <div :ref="'sortable-column-' + $vnode.key" uk-sortable="group: sortable-group" class="uk-flex uk-flex-1 uk-flex-column uk-sortable">
    <div v-for="(element, index) in column.child" :key="index" class="uk-flex uk-flex-1" :id="element.props.id">
      <builder-widget
        :data="element"
        :index="index"
        :column="column"
        :key="index"
        :active="isActive"
        @remove="onWidgetRemoved"/>
    </div>
  </div>
</div>
</template>

<script>
import BuilderWidget from './BuilderWidget'
// import PageService from '@/services/page.service'
import { mapGetters } from 'vuex'
import { EventBus } from '@/event-bus.js'
export default {
  props: ['column', 'sectionIndex', 'rowIndex', 'colIndex'],
  components: {
    BuilderWidget
  },
  data () {
    return {
      localColumn: {
        props: {}
      }
    }
  },
  computed: {
    ...mapGetters({
      widgets: 'pages/widgets'
    }),
    isActive () {
      return false
    }
  },
  mounted () {
    // `${sectionIndex}-${rowIndex}-${colIndex}-${index}`
    this.localColumn = this.column
    const $sortable = this.$uikit.util.$(this.$refs['sortable-column-' + this.$vnode.key])

    this.$uikit.util.on($sortable, 'moved', e => {
      const order = this.reOrder($sortable)
      // const order = e.detail[0].items.map(el => el.getAttribute('data-id'))
      console.log('moved: ' + order[this.$vnode.key])

      // eslint-disable-next-line no-undef
      this.column.child = _(this.widgets)
        .keyBy('props.id')
        .at(order[this.$vnode.key])
        .value()
    })

    this.$uikit.util.on($sortable, 'added', e => {
      const order = this.reOrder($sortable)
      console.log('added: ' + order[this.$vnode.key])
      // eslint-disable-next-line no-undef
      this.column.child = _(this.widgets)
        .keyBy('props.id')
        .at(order[this.$vnode.key])
        .value()
    })

    this.$uikit.util.on($sortable, 'removed', e => {
      const order = this.reOrder($sortable)
      console.log('removed: ' + order[this.$vnode.key])
      // eslint-disable-next-line no-undef
      this.column.child = _(this.widgets)
        .keyBy('props.id')
        .at(order[this.$vnode.key])
        .value()
    })
  },
  methods: {
    initEmptyElementClick (event) {
      const targetClassNames = event.target.className.split(' ')

      targetClassNames.filter(e => {
        if (e === 'uk-sortable-empty') {
          EventBus.$emit('builder:element:add', { elementIndex: 0, column: this.column })
        }
      })
    },
    reOrder (el) {
      const children = el.children
      const num = el.children.length
      const order = []

      if (num > 0) {
        for (var i = 0; i < num; i++) {
          order.push(parseInt(children[i].id))
        }
        return { [this.$vnode.key]: order }
        // document.getElementById(output).value = JSON.stringify(order);
      } else {
        return { [this.$vnode.key]: [] }
      }
    },
    onWidgetRemoved (index) {
      this.localColumn.child.splice(index, 1)
    }

  }
}
</script>
