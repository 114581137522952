<template>
  <div class="builder-element uk-flex-1 uk-width-1-1 uk-flex uk-flex-center uk-flex-middle uk-visible-toggle" :class="{'active': active}">
    <div class="uk-text-center uk-flex uk-flex-middle">
      <img :alt="data.label" :src="'/img/builder/elements/' + data.type + 'Small.svg'" data-uk-svg>
      <span class="uk-margin-small-left uk-text-middle uk-text-small uk-text-light" v-html="data.label"></span>
    </div>
    <a class="uk-position-cover" @click.prevent="select"></a>
    <div class="builder-nav-element uk-invisible-hover">
      <ul class="uk-grid uk-grid-collapse uk-iconnav">
        <!--
        <li>
          <a href="#" uk-tooltip="delay: 1000" title="Kopieren" uk-icon="icon: copy; ratio:0.9"></a>
        </li>
        -->
        <li>
          <a href="#" uk-tooltip="delay: 1000" title="Löschen" uk-icon="icon: trash; ratio:0.9" @click.prevent="remove"></a>
       </li>
      </ul>
    </div>
    <a data-uk-icon="icon:plus;ratio: 0.6"
      title="Element hinzufügen"
      uk-tooltip="delay: 1000; pos: right"
      class="uk-icon-button builder-button-element uk-invisible-hover"
      aria-expanded="false"
      @click.prevent="addElement">
    </a>
  </div>
</template>

<script>
import PageService from '@/services/page.service'
import { EventBus } from '@/event-bus.js';
export default {
  props: {
    data: {
      required: true,
      type: Object
    },
    index: {
      required: true,
      type: Number
    },
    column: {
      required: true,
      type: Object
    },
    active: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      //
    }
  },

  methods: {
    select() {
      EventBus.$emit('builder:element:selected', this.data)
    },
    addElement() {
      EventBus.$emit('builder:element:add', {elementIndex: this.$vnode.key, column: this.column})
    },
    remove() {
      PageService.removeElement(this.data.props.id).then(response => {
        this.$emit('remove', this.$vnode.key)
        EventBus.$emit('builder:element:removed');
        //EventBus.$emit('builder:element:removed', {
        //  sectionIndex: this.sectionIndex,
        //  rowIndex: this.rowIndex,
        //  columnIndex: this.columnIndex,
        //  elementIndex: this.index
        //})
      })
    }
  }

}
</script>
<style lang="scss" scoped>
  .active {
    background: rgba(29, 135, 240, 0.9);
    color: #fff;
    border: 1px solid #fff;
  }
</style>
