<template>
  <div class="uk-margin-large-bottom">
    <div class="uk-grid uk-grid-small uk-child-width-1-1" data-uk-grid>
      <div>
        <div class="uk-flex uk-flex-between uk-flex-middle">
          <div>
            <h3 class="uk-h3 uk-margin-remove">
              <span data-uk-icon="settings"></span> Builder
              <div class="uk-text-small uk-text-muted">Seite: {{ pageTitle }}</div>
            </h3>
          </div>
        </div>
      </div>
      <div>
        <div
          data-uk-sticky="offset:0"
          class="uk-background-default toolbar"
          style="padding: 15px 35px; margin-left: -40px; margin-right: -40px; border-top: 1px solid rgb(229, 229, 229); border-bottom: 1px solid rgb(229, 229, 229);"
        >
          <div class="uk-flex uk-flex-between uk-flex-middle">
            <div class="uk-flex uk-flex-middle">
              <template v-if="$store.state.pages.multilanguage && languages.length">
              <span class="uk-margin-small-right">
              Sprache:
              </span>
              <button
                class="uk-button uk-button-default uk-button-small button-rounded button-shadow"
                type="button"
              >
                {{ localeLabel }}
                <span data-uk-icon="triangle-down"></span>
              </button>
              </template>
              <div uk-dropdown="mode: click;animation: uk-animation-slide-top-small;offset: 5">
                <ul class="uk-nav uk-dropdown-nav">
                  <li v-for="language in languages" :key="language.value">
                    <a href="#" class="uk-dropdown-close" @click.prevent="changeLocale(language.value)">{{ language.label }}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="sections.length">
              <router-link
                class="uk-button uk-button-text uk-margin-right"
                :to="{name: 'pages-edit', params:{id: pageId}}"
              >Schließen</router-link>
              <button
                class="uk-button uk-button-primary"
                @click.prevent="store"
                :disabled="submitting"
              >Speichern</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="sections.length === 0" class="uk-grid" data-uk-grid>
      <div class="uk-width-1-1">
        <button
          class="uk-button uk-button-default uk-button-small button-rounded button-shadow"
          @click.prevent="onSectionAdded"
        >Neues Layout erstellen</button>
      </div>
    </div>
    <div class="uk-grid uk-grid-small uk-child-width-1-1" v-if="!showBuilder" data-uk-grid>
      <div>
        <div class="uk-background-default uk-padding-small">
          <div class="uk-grid uk-grid-small uk-grid-divider">
            <div class="uk-width-auto uk-flex uk-flex-middle">
              <a
                href="#"
                @click.prevent="showBuilder = true"
                data-uk-icon="icon: chevron-left; ratio: 2"
                class="uk-icon-link"
              ></a>
            </div>
            <div class="uk-width-medium">
              <p class="uk-margin-remove uk-text-small">Bearbeiten</p>
              <h3 class="uk-margin-remove">{{ editElementData.label }}</h3>
            </div>
            <div class="uk-width-expand uk-flex uk-flex-bottom uk-flex-right">
              <!--
              <ul class="uk-subnav" data-uk-switcher="animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium">
                <li>
                  <a href="#" @click.prevent="activateTab('content')">Inhalt</a>
                </li>
                <li>
                  <a href="#" @click.prevent="activateTab('settings')">Einstellungen</a>
                </li>

                <li>
                  <a href="#">Erweitert</a>
                </li>
              </ul>
              -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-grid uk-grid-medium uk-grid-divider" data-uk-grid>
      <div class="uk-width-large" ref="sortable-sections" uk-sortable>
        <builder-section
          v-for="(section, sectionIndex) in sections"
          :id="section.id"
          :key="sectionIndex"
          :section="section"
          @addSection="onSectionAdded"
          @removeSection="onSectionRemoved"
        />
      </div>
      <div class="uk-width-expand">
        <div class="uk-grid uk-grid-small uk-child-width-1-1" data-uk-grid>
          <div v-if="editElement">
            <BuilderEditToolbar/>
          </div>
          <component class="uk-margin-top" :is="editElement" :data="editElementData" :key="editElementKey"/>
        </div>
      </div>
    </div>
    <modal ref="selectElementModal" :large="true">
      <div class="uk-modal-header">
        <h3>Element wählen</h3>
      </div>
      <div class="uk-modal-body uk-grid-collapse uk-child-width-1-6 uk-grid" data-uk-grid>
        <div
          class="uk-card uk-card-body uk-card-hover yo-panel uk-padding-remove-horizontal uk-text-center"
          v-for="(widget, key) in widgets"
          :key="key"
        >
          <img
            :alt="widget.label"
            :src="'/img/builder/elements/' + widget.type + '.svg'"
            data-uk-svg
          />
          <p
            class="uk-margin-small-top uk-margin-remove-bottom uk-text-truncate"
            v-html="widget.label"
          ></p>
          <a
            href="#"
            class="uk-position-cover"
            @click.prevent="addElement(widget.type, widget.label)"
          ></a>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import Modal from '@/components/Modal'
import PageService from '@/services/page.service'
import axios from 'axios'
import BuilderSection from './components/BuilderSection'
import BuilderColumn from './components/BuilderColumn'
import BuilderEditToolbar from './components/BuilderEditToolbar'
import { EventBus } from '@/event-bus.js'
import 'izitoast/dist/css/iziToast.min.css'
import iziToast from 'izitoast/dist/js/iziToast.min.js'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    BuilderSection,
    BuilderColumn,
    BuilderEditToolbar,
    Modal,
    accordionEdit: () => import('./components/elements/AccordionEdit'),
    auctionEdit: () => import('./components/elements/AuctionEdit'),
    blogPostsEdit: () => import('./components/elements/BlogPostsEdit'),
    vehicleEdit: () => import('./components/elements/VehicleEdit'),
    vehicleModelListEdit: () => import('./components/elements/VehicleModelListEdit'),
    cityVehicleModelListEdit: () => import('./components/elements/CityVehicleModelListEdit'),
    cityVehicleStatusListEdit: () => import('./components/elements/CityVehicleStatusListEdit'),
    vehicleMakeListEdit: () => import('./components/elements/VehicleMakeListEdit'),
    cityContentEdit: () => import('./components/elements/CityContentEdit'),
    gridEdit: () => import('./components/elements/GridEdit'),
    headlineEdit: () => import('./components/elements/HeadlineEdit'),
    imageElementEdit: () => import('./components/elements/ImageElementEdit'),
    contentElementEdit: () => import('./components/elements/ContentEdit'),
    breadcrumbElementEdit: () => import('./components/elements/BreadcrumbEdit'),
    buttonElementEdit: () => import('./components/elements/ButtonEdit'),
    htmlElementEdit: () => import('./components/elements/HtmlEdit'),
    panelEdit: () => import('./components/elements/PanelEdit'),
    listElementEdit: () => import('./components/elements/ListEdit'),
    descriptionListEdit: () =>
      import('./components/elements/DescriptionListEdit'),
    sectionEdit: () => import('./components/SectionEdit'),
    rowEdit: () => import('./components/RowEdit'),
    generalSettings: () => import('./components/GeneralSettings')
  },
  data () {
    return {
      pageId: this.$route.params.id,
      showBuilder: true,
      editElement: null,
      editElementData: null,
      editElementKey: 0,
      addElementColumn: null,
      addElementColumnIndex: null,
      addSectionIndex: null,
      addElementIndex: null,
      addColumnIndex: null,
      addRowIndex: null,
      sections: [],
      submitting: false,
      widgets: [],
      orderedSections: [],
      pageTitle: ''
    }
  },
  mounted () {
    if (this.pageId) {
      this.getSections()
      this.getPage()
    }

    if (typeof this.$route.query.lang !== 'undefined') {
      this.setLocale(this.$route.query.lang)
    }

    axios.get('./../../../assets/json/widgets.json').then(response => {
      // if (this.user.data.tenant.profile.package !== 'Silber') {
      // eslint-disable-next-line no-undef
      //  this.widgets = _.omitBy(response.data, w => w.type === 'blog-posts')
      // } else {
      this.widgets = response.data
      // }
    })

    EventBus.$on('builder:element:selected', this.onElementSelected)
    EventBus.$on('builder:element:removed', this.onElementRemoved)
    EventBus.$on('builder:element:add', this.onElementAdd)
    EventBus.$on('builder:element:edit', this.onElementEdit)

    const $sortable = this.$refs['sortable-sections']
    this.$uikit.util.on($sortable, 'moved', e => {
      const order = this.reOrder($sortable)
      this.orderedSections = order.reduce(
        (p, c) => p.concat(this.sections.find(s => s.id === c)),
        []
      )
    })
  },
  beforeDestroy () {
    EventBus.$off()
  },
  computed: {
    ...mapGetters({
      locale: 'pages/locale',
      languages: 'pages/languages',
      user: 'auth/user'
    }),
    localeLabel () {
      // eslint-disable-next-line no-undef
      return ((typeof this.locale !== 'undefined') ? _.find(this.languages, l => { return l.value === this.locale }).label : 'Deutsch')
    }
  },
  methods: {
    ...mapActions({
      addWidget: 'pages/addWidget',
      setLocale: 'pages/setLocale'
    }),
    getSections () {
      PageService.getSections(this.pageId).then(response => {
        this.sections = response.data
      })
    },
    getPage () {
      PageService.get(this.pageId).then(response => {
        this.pageTitle = response.data.title
      })
    },
    onColumnUpdated (data) {
      const childs = this.sections[data.section].params.child[data.row].child[
        data.column
      ].child
      // eslint-disable-next-line no-undef
      const newChilds = _.assign(childs, data.child)
      // console.log(JSON.parse(JSON.stringify(newChilds)))
      this.sections[data.section].params.child[data.row].child[
        data.column
      ].child = newChilds
    },
    editSection (section) {
      this.showBuilder = false
      this.editElement = 'sectionEdit'
      this.editElementData = section.params.props
    },
    removeSection (id, index) {
      if (id) {
        PageService.deleteSection(id).then(response => {
          this.submitting = false
          this.sections.splice(index, 1)
          iziToast.success({
            title: 'OK',
            message: 'Bereich gelöscht!',
            timeout: 3000,
            position: 'topRight',
            transitionIn: 'fadeInLeft'
          })
          // this.$store.dispatch('pages/getWidgets')
        })
      } else {
        this.sections.splice(index, 1)
      }
    },
    editRow (row) {
      this.showBuilder = false
      this.editElement = 'rowEdit'
      this.editElementData = row
    },
    onSectionAdded (index) {
      this.sections.splice(index + 1, 0, {
        id: null,
        label: 'Neuer Bereich',
        params: {
          child: [
            {
              type: 'row',
              props: {
                breakpoint: 'm',
                fixed_width: 'large',
                gutter: '',
                height: '',
                layout: '1-1',
                margin: '',
                width: '',
                width_expand: ''
              },
              child: [
                {
                  child: [],
                  type: 'column',
                  props: {
                    widths: ['1-1'],
                    image_position: 'center-center',
                    media_overlay_gradient: '',
                    padding: '',
                    style: '',
                    text_color: '',
                    vertical_align: ''
                  }
                }
              ]
            }
          ],
          type: 'section',
          props: {
            name: 'Neuer Bereich',
            padding: '',
            style: 'default',
            width: '',
            image: '',
            image_size: '',
            image_width: '',
            image_height: '',
            image_position: 'center-center',
            image_overlay: '',
            image_effect: '',
            image_visibility: '',
            preserve_color: false,
            scrollspy: false,
            scrollspy_animation: 'fade',
            scrollspy_delay: '200',
            css_id: ''
          }
        }
      })
    },
    onSectionRemoved (index) {
      this.sections.splice(index, 1)
    },
    onElementSelected (element) {
      // this.showBuilder = false;
      this.editElementKey = element.props.id
      this.editElement = element.type + 'Edit'
      this.editElementData = element.props
    },
    onElementAdd ({ elementIndex, column }) {
      this.editElement = null
      this.$refs.selectElementModal.open()
      this.addElementColumn = column
      this.addElementColumnIndex = elementIndex
    },
    onElementEdit ({ type, data }) {
      // this.showBuilder = false;
      this.editElementKey = data.id
      this.editElement = type
      this.editElementData = typeof data.params === 'undefined' ? data : data.params.props
    },
    onElementRemoved () {
      // this.sections[sectionIndex].params.child[rowIndex].child[columnIndex].child.splice(elementIndex)
      this.store()
    },
    async addElement (type, label) {
      const defaultProps = await axios.get(
        `./../../../assets/json/defaultProps/${type}.json`
      )
      const elemObj = {
        type: type,
        label: label,
        props: defaultProps.data
      }
      /*
      elemObj.props.random = Math.floor(Math.random() * 100)
      this.addElementColumn.child.splice(
        this.addElementColumnIndex + 1,
        0,
        elemObj
      )
      */
      this.addWidget(elemObj).then(response => {
        // this.sections[this.addSectionIndex].params.child[this.addRowIndex].child[
        // this.addColumnIndex
        // ].child.push(response);
        this.addElementColumn.child.splice(
          this.addElementColumnIndex + 1,
          0,
          response
        )
      })

      this.$refs.selectElementModal.close()
    },
    initEmptyElementClick (
      event,
      sectionIndex,
      elementIndex,
      columnIndex,
      rowIndex
    ) {
      const targetClassNames = event.target.className.split(' ')

      targetClassNames.filter(e => {
        if (e === 'uk-sortable-empty') {
          this.onElementAdd({
            sectionIndex: sectionIndex,
            elementIndex: elementIndex,
            columnIndex: columnIndex,
            rowIndex: rowIndex
          })
        }
      })
    },
    store () {
      this.submitting = true
      let sections = this.sections
      if (this.orderedSections.length) {
        sections = this.orderedSections
      }
      PageService.storeSection(this.$route.params.id, sections).then(
        response => {
          this.submitting = false
          for (let i = 0; i < response.data.length; i++) {
            this.sections[i].id = response.data[i].id
          }
          iziToast.success({
            title: 'OK',
            message: 'Seite gespeichert!',
            timeout: 3000,
            position: 'topRight',
            transitionIn: 'fadeInLeft'
          })
          // this.$store.dispatch('pages/getWidgets')
        }
      )
    },
    activateTab (tab) {
      switch (tab) {
        case 'settings':
          this.editElement = 'generalSettings'
          break
      }
      EventBus.$emit('builder:select:tab', tab)
    },
    reOrder (el) {
      const children = el.children
      const num = el.children.length
      const order = []

      if (num > 0) {
        for (var i = 0; i < num; i++) {
          order.push(parseInt(children[i].id))
        }
        return order
        // document.getElementById(output).value = JSON.stringify(order);
      }
    },
    changeLocale (locale) {
      this.editElement = null
      this.setLocale(locale)
      this.$router.push({
        name: 'pages-edit-builder',
        params: { id: this.pageId },
        query: {
          lang: locale
        }
      })
      this.getSections()
    }
  }
}
</script>
<style lang="scss" scoped>
.uk-button-default {
  background-color: #fff;
  &:hover {
    box-shadow: 0 0 0 1px rgba(197, 197, 197, 0.85),
      0 2px 5px 0 rgba(197, 197, 197, 0.85), 0 1px 1.5px 0 rgba(0, 0, 0, 0.07),
      0 1px 2px 0 rgba(0, 0, 0, 0.08);
  }
}

.uk-button-small {
  border-radius: 3px;
  padding: 3px 14px;
  font-size: 1rem;
  text-transform: none;
  &.muted {
    color: #999;
  }
}
.button-shadow {
    box-shadow: 0 0 0 1px rgba(43,45,80,.1), 0 2px 5px 0 rgba(43,45,80,.08), 0 1px 1.5px 0 rgba(0,0,0,.07), 0 1px 2px 0 rgba(0,0,0,.08);
    border: none;
}
</style>
