<template>
  <div class="uk-grid uk-grid-small uk-child-width-1-1" data-uk-grid>
    <div class="builder-section uk-visible-toggle">
      <div class="uk-flex uk-flex-middle uk-margin-small-bottom">
        <h3 class="uk-text-small uk-text-uppercase uk-margin-remove uk-drag">{{ section.params.props.name }}</h3>
        <div class="uk-margin-small-left">
          <ul class="uk-invisible-hover uk-iconnav">
            <li>
              <a
                href="#"
                uk-tooltip="delay: 1000"
                title="Bereich bearbeiten"
                uk-icon="icon: pencil; ratio:0.8"
                @click.prevent="editSection(section)">
              </a>
              </li>
                <!--
                <li>
                  <a href="#" uk-tooltip="delay: 1000" title="Kopieren" uk-icon="icon: copy; ratio:0.8"></a>
                </li>
              -->
              <li>
                <a
                  href="#"
                  uk-tooltip="delay: 1000"
                  title="Bereich löschen"
                  uk-icon="icon: trash; ratio:0.8"
                  @click.prevent="removeSection">
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!--<div ref="sortable-rows" uk-sortable>-->
          <div>
          <builder-row
            v-for="(row, index) in section.params.child"
            :key="index"
            :row="row"
            :id="index"
            :section-index="$vnode.key"
            @addRow="onRowAdded"
            @removeRow="onRowRemove"/>
        </div>
        <div class="uk-width-1-1 uk-invisible-hover">
          <div class="uk-margin-small-top">
            <a
              href="#"
              class="uk-text-muted"
              data-uk-icon="icon: plus-circle"
              @click.prevent="addSection"
              uk-tooltip="delay: 1000"
              title="Bereich hinzufügen">
            </a>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import BuilderRow from './BuilderRow'
import { EventBus } from '@/event-bus.js'
import PageService from '@/services/page.service'
import 'izitoast/dist/css/iziToast.min.css'
import iziToast from 'izitoast/dist/js/iziToast.min.js'
export default {
  props: {
    section: {
      type: Object,
      required: true
    }
  },
  components: {
    BuilderRow
  },
  data () {
    return {
      //
    }
  },
  mounted () {
    /*
    let $sortable = this.$refs['sortable-rows']
    this.$uikit.util.on($sortable, 'moved', e => {
      const order = this.reOrder($sortable)
      console.log(order)
      this.section.params.child = order
    });
    */
  },
  methods: {
    addSection () {
      this.$emit('addSection', this.$vnode.key)
    },
    editSection (section) {
      EventBus.$emit('builder:element:edit', { type: 'sectionEdit', data: section })
    },
    removeSection () {
      if (this.section.id) {
        PageService.deleteSection(this.section.id).then(
          response => {
            this.submitting = false
            this.$emit('removeSection', this.$vnode.key)
            iziToast.success({
              title: 'OK',
              message: 'Bereich gelöscht!',
              timeout: 3000,
              position: 'topRight',
              transitionIn: 'fadeInLeft'
            })
            // this.$store.dispatch('pages/getWidgets')
          }
        )
      } else {
        this.$emit('removeSection', this.$vnode.key)
      }
    },
    onRowAdded (index) {
      this.section.params.child.splice(index + 1, 0, {
        type: 'row',
        props: {
          breakpoint: 'm',
          fixed_width: 'large',
          gutter: '',
          height: '',
          layout: '1-1',
          margin: '',
          width: '',
          width_expand: '',
          padding_remove_top: false,
          padding_remove_bottom: false
        },
        child: [{
          child: [],
          type: 'column',
          props: {
            widths: ['1-1'],
            image_position: 'center-center',
            media_overlay_gradient: '',
            padding: '',
            style: '',
            text_color: '',
            vertical_align: ''
          }
        }]
      })
    },
    onRowRemove (index) {
      this.section.params.child.splice(index, 1)
      if (this.section.params.child.length === 0) {
        this.removeSection()
      } else {
        EventBus.$emit('builder:element:removed')
      }
    },
    reOrder (el) {
      const children = el.children
      const num = el.children.length
      const order = []

      for (var i = 0; i < num; i++) {
        order.push(children[i].id)
      }
      return order.map(i => this.section.params.child[i])
    }
  }
}
</script>
