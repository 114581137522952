<template>
<div>
  <ul data-uk-tab="connect: #element-settings; animation: uk-animation-slide-right-medium">
    <li><a href="#">Inhalt</a></li>
    <li><a href="#">Einstellungen</a></li>
</ul>
</div>
<!--
  <div class="uk-background-default uk-padding-small">
    <div class="uk-grid uk-grid-small uk-grid-divider">
      <div class="uk-width-auto uk-flex uk-flex-middle">
        <a
          href="#"
          @click.prevent="goBack"
          data-uk-icon="icon: chevron-left; ratio: 2"
          class="uk-icon-link"
        ></a>
      </div>
      <div class="uk-width-medium">
        <p class="uk-margin-remove uk-text-small">Bearbeiten</p>
        <h3 class="uk-margin-remove">{{ label }}</h3>
      </div>
      <div class="uk-width-expand uk-flex uk-flex-bottom uk-flex-right">
        <ul
          class="uk-subnav"
          data-uk-switcher="animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium"
        >
          <li>
            <a href="#">Inhalt</a>
          </li>
          <li>
            <a href="#">Einstellungen</a>
          </li>
          <li>
            <a href="#">Erweitert</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  -->
</template>

<script>
export default {
  /*
  props: {
    backPath: {
      required: true,
      type: String
    },
    label: {
      required: true,
      type: String
    }
  },
  */
  methods: {
    goBack () {
      //
    }
  }
}
</script>
