<template>
  <div class="builder-grid uk-visible-toggle">
    <div style="position: absolute;top: 0;right: 0;padding-left: 6px;transform: translateX(100%);z-index: 8;">
      <div class="builder-nav-grid uk-builder-grid-hover">
        <ul class="uk-invisible-hover uk-iconnav uk-iconnav-vertical">
          <li>
            <a
              href="#"
              uk-tooltip="delay: 1000"
              title="Bearbeiten"
              uk-icon="icon: pencil; ratio:0.9"
              @click.prevent="editRow(row)">
            </a>
          </li>
          <!--
          <li>
            <a href="#" uk-tooltip="delay: 1000" title="Kopieren" uk-icon="icon: copy; ratio:0.9"></a>
          </li>
          -->
          <li>
            <a
              href="#"
              uk-tooltip="delay: 1000; pos: left"
              title="Löschen"
              uk-icon="icon: trash; ratio:0.9"
              @click.prevent="remove">
            </a>
          </li>
        </ul>
      </div>
    </div>
    <a
      class="uk-invisible-hover uk-text-muted"
      title="Row hinzufügen"
      uk-tooltip="delay: 1000; pos: left"
      uk-icon="icon: plus-circle; ratio:0.9"
      style="position: absolute;bottom: -15px;right: 0;padding-left: 6px;transform: translateX(100%);z-index: 8;"
      @click.prevent="addRow()">
    </a>
    <div class="uk-grid uk-child-width-expand">
      <builder-column
        v-for="(column, i) in row.child"
        :key="i"
        :column="column"
        :row-index="$vnode.key"
        :col-index="i"
        :section-index="sectionIndex"
      />
    </div>
  </div>
</template>

<script>
import BuilderColumn from './BuilderColumn'
import { EventBus } from '@/event-bus.js'
import PageService from '@/services/page.service'
export default {
  props: {
    row: {
      type: Object,
      required: true
    },
    sectionIndex: {
      required: false
    }
  },
  components: {
    BuilderColumn
  },
  methods: {
    addRow () {
      this.$emit('addRow', this.$vnode.key)
    },
    editRow (row) {
      EventBus.$emit('builder:element:edit', { type: 'rowEdit', data: row })
    },
    async remove () {
      // eslint-disable-next-line no-undef
      await _.forEach(this.row.child, c => {
        // eslint-disable-next-line no-undef
        _.forEach(c.child, e => {
          PageService.removeElement(e.props.id)
        })
      })
      this.$emit('removeRow', this.$vnode.key)
    }
  }
}
</script>
