<template>
  <div class="uk-container uk-container-expand">
    <div class="uk-grid uk-child-width-1-1 uk-grid-small">
      <div>
        <builder />
      </div>
    </div>
  </div>
</template>

<script>
import Builder from '@/components/builder/Index'

export default {
  components: {
    Builder
  },
  mounted () {
    this.$store.commit('SET_LAYOUT', 'builder-layout')
    this.$store.dispatch('pages/getWidgets')
  }
}
</script>
